import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Contact extends React.Component{
    render(){
        return(
            <section id="contact_page" className="hero contact-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">

                        <div className="column is-4 static-part is-left padding-0">
                           
                        </div>{/*STATIC PART END */}

                        <div className="column is-8 content-part is-right padding-0">
                            <div className="inner">
                                <h1 className="page-title">KONTAKT</h1>
                                <span className="zigzag"></span>
                                <div className="divider"></div>

                                <div className="info-box">
                                    <h4>ĆATIĆ COMPANY DOO</h4>
                                    <p>31300 Prijepolje, Ivanje bb <a href="https://www.google.com/maps/dir/Current+Location/Prijepolje,+Serbia"><FontAwesomeIcon icon={['fas', 'location-arrow']} aria-hidden="true" />GOOGLE MAPS</a></p>
                                    <a href="tel:+381 (0)33 771 420">t: +381 (0)33 771 420</a>
                                    <a href="tel:+381 (0)33 771 564">t: +381 (0)33 771 564</a>
                                    <a href="tel:+381 (0)33 743 780">f: +381 (0)33 743 780</a>
                                    <a href="mailto:office@catic.rs">e: office@catic.rs</a>
                                </div>

                                <h1 className="page-title">POŠALJITE NAM PORUKU</h1>
                                <span className="zigzag"></span>
                                <div className="divider"></div>

                                <form className="form columns is-multiline">
                                    <label className="column is-6">
                                        <input type="text" name="name" placeholder="Ime i prezime*" required />
                                    </label>
                                    <label className="column is-6">
                                        <input type="text" name="email" placeholder="Email*" required />
                                    </label>
                                    <label className="column is-12">
                                        <textarea name="message" placeholder="Poruka*" required></textarea>
                                    </label>

                                    <button type="submit" className="button default">POŠALJI</button>
                                </form>
                            </div>
                        </div>{/* CONTENT PART END */}

                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default Contact